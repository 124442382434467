angular.module('app.controllers').controller('MerklisteCtrl', [
  '$scope',
  '$rootScope',
  '$location',
  'BookmarkService',
  function(self,
  root,
  $location,
  BookmarkService) {
    if (root.savedArticles && root.savedArticles.length === 0) {
      $location.path("/");
    }
    return self.removeItem = function(id) {
      return BookmarkService.removeItem(id);
    };
  }
]);
